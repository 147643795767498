.App {
    min-width: 720px;
    overflow:hidden;
}

.index-layout {
    min-height: 100vh;
}

.index-header {
    position: relative;
    background-color: #292929;
}

.index-header img {
    width: 60px;
    height:60px;
    margin-bottom: 4px;
}


.index-header::after {
    content: ' ';
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-image: linear-gradient(to right, #F0F0F0, #FFF);
}

.index-content {
    padding: 14px;
    min-height: 100%;
}

.index-footer {
    position: relative;
    text-align: center;
    background-color: #FFF;
}

.index-footer::after {
    content: ' ';
    position: absolute;
    height: 1px;
    top: 0;
    left: 0;
    width: 100vw;
    background-image: linear-gradient(to right, #F0F0F0, #FFF);
}

.add-dispatcher-container {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  padding: 32px;
  min-height: calc(100vh - 291px);
}

.add-dispatcher-container .add-dispatcher-step-content {
  flex: 1;
  width: 100%;
  margin-top: 32px;
  background-color: #FFF;
  border-radius: 4px;
}

.add-dispatcher-container .add-dispatcher-step-current {
  width: 1024px;
  margin: 0 auto;
}
.dispatcher-basic-container {
  width: 640px;
  padding: 32px 0;
  margin: auto;
}


.add-partner-container {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  padding: 32px;
  min-height: calc(100vh - 291px);
}

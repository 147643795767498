.details-content {
    padding: 24px;
    background-color: #FFF;
}

.details-basic {
    display: flex;
    flex-direction: row;
}

.details-image {
    width: 120px;
}

.details-image img {
    width: 120px;
    height: 120px;
}

.details-service {
    flex: 1;
    margin-left: 48px;
}

.login-container {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-image: linear-gradient(160deg, #1E90FF 20%, #0fb3ff 80%);
}

.login-body {
    flex: 1;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-footer {
    text-align: center;
}

.form-wrapper {
    width: 480px;
    padding: 48px;
    background-color: #FFF;
    text-align: center;
    /* border-radius: 4px; */
    transition-duration: 0.25s;
    border-radius: 4px;
}

.form-wrapper h2 {
    text-align: center;
}
